<template>
  <DialogBase
    visible
    ref="dialog"
    title="移動登録"
    icon="mdi-car"
    fullscreen
    midium
    headerButtonDisp
    @clickClose="close(false)"
  >
    <v-row>
      <v-col colspan="4">
        <DatePicker
          v-model="targetdate"
          label="対象年月日"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
          max-width="300"
        />
      </v-col>
      <v-col colspan="4">
        <v-autocomplete
          v-model="selectShozoku"
          :items="shozokus"
          label="事業所"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
          max-width="300"
        >
        </v-autocomplete>
      </v-col>
      <v-col colspan="4">
        <v-text-field
          v-model.number="distance"
          label="移動距離"
          suffix="km"
          type="number"
          step="0.1"
          prepend-inner-icon="mdi-map-marker-distance"
          :rules="[Rules.Required]"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col colspan="4">
        <TimePicker
          v-model="starttime"
          label="開始時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
      </v-col>
      <v-col colspan="4">
        <TimePicker
          v-model="endtime"
          label="終了時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
      </v-col>
      <v-col colspan="4"></v-col>
    </v-row>
    <div class="d-flex flex-row-reverse mt-5">
      <v-btn color="primary" @click="setdata">
        設定
      </v-btn>
      <v-btn color="#fff" @click="close(false)" class="mx-10">
        閉じる
      </v-btn>
    </div>
    <template v-if="isShow()" class="mt-10">
      <table>
        <thead>
          <tr>
            <th v-for="(header, i) in setItemHeader" :key="i">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in setitems" :key="index">
            <td>{{ displayDate(item.targetDate) }}</td>
            <td>{{ item.affiliationName }}</td>
            <td>
              {{ item.distance }}
            </td>
            <td>
              {{ item.starttime }}
            </td>
            <td>{{ item.endtime }}</td>
            <td>
              <v-icon
                small
                class="ml-1"
                color="error"
                @click="deleteRow(index)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex flex-row-reverse mt-5">
        <v-btn type="login" color="success" @click="regist">
          登録
        </v-btn>
      </div>
    </template>
    <v-card outlined class="mt-5">
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">当月移動登録済データ</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="dataheaders"
              :items="databodies"
              :items-per-page-options="5"
              class="elevation-1"
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </DialogBase>
</template>
<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import TemporaryDistance from "@/models/TemporaryDistance";
import "vue-good-table/dist/vue-good-table.css";
import { BUSYO_CODE as BusyoCode } from "@/defines";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);

export default {
  name: "TemporaryDistanceDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  data: () => ({
    targetdate: DATENOW,
    userincode: "",
    usercode: "",
    shozokus: [],
    selectShozoku: { code: "", name: "" },
    distance: 0,
    starttime: "00:00",
    endtime: "00:00",
    setItemHeader: [
      "対象年月日",
      "事業所",
      "移動距離",
      "開始時間",
      "終了時間",
      "action"
    ],
    setitems: [],
    dataheaders: [],
    databodies: [],
    panel: 0
  }),
  methods: {
    close(result) {
      this.$store.commit("user/logout");
      this.resolve(result);
      this.$close();
    },
    isShow() {
      return this.setitems.length > 0;
    },
    setdata() {
      console.log("setdata");
      if (!this.validate()) return;
      const item = {
        targetDate: this.targetdate,
        allifiationId: this.selectShozoku.code,
        affiliationName: this.selectShozoku.name,
        distance: this.distance,
        starttime: this.starttime,
        endtime: this.endtime
      };
      this.setitems.push(item);
    },
    displayDate(targetDate) {
      const dt = targetDate.split("-");
      return dt[0] + "年" + dt[1] + "月" + dt[2] + "日";
    },
    async regist() {
      console.log("regist");
      this.$loading();
      try {
        const param = [];
        this.setitems.forEach(e => {
          const item = new TemporaryDistance(
            e.targetDate,
            e.allifiationId,
            this.userincode,
            e.distance,
            e.starttime,
            e.endtime
          );
          param.push(item);
        });

        await this.$post(this.Paths.tmpDistance, param);
        this.$info("更新しました。");
        this.setitems = [];
        this.$store.commit("user/logout");
        this.resolve(true);
        this.$close();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteRow(index) {
      console.log("deleteRow", index);
      if (await this.deleteConfirm()) {
        this.setitems = this.setitems.filter((x, i) => i !== index);
      }
    },
    async deleteConfirm() {
      return await this.$deleteConfirm("削除します。\nよろしいですか？");
    },
    validate() {
      if (this.selectShozoku.code === "") {
        this.$warning("事業所を選択してください");
        return false;
      }
      if (this.starttime === "") {
        this.$warning("開始時間を設定してください");
        return false;
      }
      if (this.endtime === "") {
        this.$warning("終了時間を設定してください");
        return false;
      }
      const dt = new Date();
      const start = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        dt.getDate(),
        this.starttime.split(":")[0],
        this.starttime.split(":")[1],
        0
      );
      const end = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        dt.getDate(),
        this.endtime.split(":")[0],
        this.endtime.split(":")[1],
        0
      );
      if (
        this.starttime !== "00:00" &&
        this.endtime !== "00:00" &&
        end <= start
      ) {
        this.$warning("開始時間と終了時間を設定してください");
        return false;
      }
      return true;
    },
    async setPulldown() {
      const param = {
        code1: BusyoCode.WELFARE
      };
      const encode = encodeURI(JSON.stringify(param));
      this.shozokus = await this.$get(this.Paths.shozoku, "query=" + encode);
    }
  },
  async created() {
    console.log("created", this.args);
    this.userincode = this.args.userincode;
    this.usercode = this.args.usercode;
    this.dataheaders = this.args.dataheaders;
    this.databodies = this.args.databodies;

    await this.setPulldown();
  },
  mounted() {
    console.log("mounted", this.args);
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}
</style>
